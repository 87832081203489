var render = function render(){var _vm=this,_c=_vm._self._c;return _c('loader',_vm._b({attrs:{"text":"Loading Locations"}},'loader',{ loading: _vm.loading },false),[_c('columns',[_c('column',[_c('div',{staticClass:"index-rows"},_vm._l((_vm.schedules.data),function(schedule){return _c('div',{key:schedule.id,staticClass:"box is-marginless"},[_c('columns',[_c('column',[_c('p',{staticClass:"is-size-5"},[_vm._v(_vm._s(_vm._f("date")(schedule.scheduled_for)))])]),(schedule.work_order)?_c('column',{staticClass:"is-narrow is-flex is-align-items-center"},[_c('router-link',{staticClass:"mr-1",attrs:{"to":{
                                    name: 'work-order-manager',
                                    params: {
                                        workOrder: schedule.work_order.uuid
                                    }
                                }}},[_vm._v(" #"+_vm._s(schedule.work_order.number)+" ")])],1):_vm._e(),_c('column',{staticClass:"is-1 is-flex is-align-items-center"},[_c('span',{staticClass:"tag",class:{
                                'is-warning': schedule.status === 'skipped',
                                'is-success': schedule.status === 'created',
                            }},[_c('icon',{staticClass:"mr-1",attrs:{"type":_vm.getIcon(schedule.status) === 'check' ? 'fas' : 'far',"icon":_vm.getIcon(schedule.status)}}),_vm._v(" "+_vm._s(_vm._f("ucwords")(schedule.status))+" ")],1)]),_c('column',{staticClass:"is-narrow"},[_c('dropdown-wrapper',{staticClass:"navbar-item",attrs:{"position":"bottom-end"}},[_c('action-button',{staticClass:"is-small is-rounded",attrs:{"slot":"trigger"},slot:"trigger"},[_c('icon',{attrs:{"icon":"cogs"}})],1),(schedule.status === 'scheduled')?_c('dropdown-item',{on:{"click":function($event){return _vm.changeDate(schedule)}}},[_c('span',{staticClass:"is-flex"},[_c('icon',{staticClass:"mr",attrs:{"icon":"calendar","type":"far"}}),_c('span',[_vm._v("Change Date")])],1)]):_vm._e(),(schedule.status === 'scheduled')?_c('dropdown-item',{on:{"click":function($event){return _vm.markAsSkipped(schedule)}}},[_c('span',{staticClass:"is-flex"},[_c('icon',{staticClass:"mr-1",attrs:{"icon":"circle","type":"far"}}),_vm._v(" Mark as Skipped ")],1)]):_vm._e(),(schedule.status === 'created' || schedule.status === 'skipped')?_c('dropdown-item',[_vm._v(" No actions available. ")]):_vm._e()],1)],1)],1)],1)}),0)])],1),(_vm.schedules.data.length)?_c('pager',{attrs:{"pageable":_vm.schedules,"context":"Template","jump-controls":""},on:{"nav":_vm.goToPage}}):_vm._e(),_c('no-items-to-display',{attrs:{"item-count":_vm.schedules.data.length,"heading":"There are no schedules available for display."}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }