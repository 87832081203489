<template>
    <loader v-bind="{ loading }" text="Loading Locations">
        <columns>
            <column>
                <div class="index-rows">
                    <div class="box is-marginless" v-for="schedule in schedules.data" :key="schedule.id">
                        <columns>
                            <column>
                                <p class="is-size-5">{{ schedule.scheduled_for | date }}</p>
                            </column>
                            <column v-if="schedule.work_order" class="is-narrow is-flex is-align-items-center">
                                <router-link 
                                    class="mr-1"
                                    :to="{
                                        name: 'work-order-manager',
                                        params: {
                                            workOrder: schedule.work_order.uuid
                                        }
                                    }">
                                    #{{ schedule.work_order.number }}
                                </router-link>
                            </column>
                            <column class="is-1 is-flex is-align-items-center">
                                <span class="tag" :class="{
                                    'is-warning': schedule.status === 'skipped',
                                    'is-success': schedule.status === 'created',
                                }">
                                    <icon 
                                        :type="getIcon(schedule.status) === 'check' ? 'fas' : 'far'" 
                                        class="mr-1" 
                                        :icon="getIcon(schedule.status)"
                                    />
                                    {{ schedule.status | ucwords }}
                                </span>
                            </column>
                            <column class="is-narrow">
                                <dropdown-wrapper class="navbar-item" position="bottom-end">
                                    <action-button
                                        class="is-small is-rounded"
                                        slot="trigger">
                                        <icon icon="cogs"/>
                                    </action-button>

                                    <dropdown-item @click="changeDate(schedule)" v-if="schedule.status === 'scheduled'">
                                        <span class="is-flex">
                                            <icon class="mr" icon="calendar" type="far" />
                                            <span>Change Date</span>
                                        </span>
                                    </dropdown-item>
                            
                                    <dropdown-item v-if="schedule.status === 'scheduled'" @click="markAsSkipped(schedule)">
                                        <span class="is-flex">
                                            <icon class="mr-1" icon="circle" type="far" />
                                            Mark as Skipped
                                        </span>
                                    </dropdown-item>
                                    
                                    <dropdown-item v-if="schedule.status === 'created' || schedule.status === 'skipped'">
                                        No actions available.
                                    </dropdown-item>

                                </dropdown-wrapper>
                            </column>
                        </columns>
                    </div>
                </div>
            </column>
        </columns>

        <pager 
            v-if="schedules.data.length" 
            :pageable="schedules" 
            context="Template"
            jump-controls @nav="goToPage"
        />

        <no-items-to-display 
            :item-count="schedules.data.length"
            heading="There are no schedules available for display." 
        />
    </loader>
</template>
<script>
import { get } from '@/api/request'
import moment from 'moment'
import { workOrderTemplate as backend } from '@/api'
import { changeScheduleDate } from '@/modals'

export default {

    data: () => ({
        loading: true,
        filtering: false,
        downloading: false,
        schedules: {
            data: []
        }
    }),

    created() {
        if (this.$route.query) {
            this.filters = { ...this.$route.query, ...this.filters }
        }
        this.loadSchedules()
    },

    methods: {
        loadSchedules() {
            get('v1' + this.$route.fullPath, ({ data }) => {
                this.schedules = data
                this.loading = false
            }, () => {
                this.loading = false
            }, { params: this.filters })
        },
        goToPage(path) {
            this.$router.push(path)
        },
        runSearch() {
            this.$router.push({
                name: 'work-order-template-schedules',
                query: this.filters
            });
        },
        clearFilters() {
            this.$router.push({
                name: 'work-order-template-schedules',
            });
        },
        jobIsOverdue(workOrder) {
            if (!workOrder.ending_at) return false
            return moment(workOrder.ending_at).isBefore(moment()) && !workOrder.closed_off_at
        },
        updateAdvancedFilters(filters) {
            this.filters = { ...this.filters, ...filters }
        },
        getIcon(status) {
            switch(status) {
                case 'scheduled': return 'clock'
                case 'skipped': return 'circle'
                case 'created': return 'check'
            }
        },
        changeDate(schedule) {
            changeScheduleDate(schedule).then(changed => {
                if(changed) {
                    this.loadSchedules()
                }
            })
        },
        async markAsSkipped(schedule) {
            if(await this.$confirm({
                title: 'Skip Schedule',
                message: 'Are you sure you want to skip this schedule?'
            })) {
                backend.skipSchedule({
                    workOrderId: schedule.work_order_template_id,
                    scheduleId: schedule.id,
                }, () => {
                    this.$toast.success('Schedule has been skipped.')
                    this.loadSchedules()
                }, () => {})
            }
        }
    },

    computed: {
        isFiltering() {
            return Object.keys(this.$route.query).length > 0
        }
    },

    watch: {
        '$route': 'loadSchedules'
    }

}
</script>